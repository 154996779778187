// 动画效果

// 我的大屏首页 闪烁动画1
.home_flicker1 {
  offset-path: path('M 0 0 L 1600 0');
  offset-distance: 0;
  animation: home_flicker1_keyframes 5s linear infinite;
  @keyframes home_flicker1_keyframes {
    from {
      offset-distance: 0;
    }
    to {
      offset-distance: 100%;
    }
  }
}

// 大统计标题 闪烁动画1
.statistics_flicker1 {
  animation: statistics_flicker1_keyframes 2s linear infinite;

  @keyframes statistics_flicker1_keyframes {
    0% {
      stroke-opacity: 1;
    }
    100% {
      stroke-opacity: 0.5;
    }
  }
}

// 大统计标题 文字 闪烁动画1
.statistics_txt_flicker1 {
  animation: statistics_txt_flicker1_keyframes 2s linear infinite;
  @keyframes statistics_txt_flicker1_keyframes {
    14%, 42% { transform: scale(1.1) }
    28%, 70% { transform: scale(1) }
  }
}


// 图表标题 显示动画1
.chartTitle_show1_1 {
  animation: chartTitle_show1_1_keyframes 2s infinite;
  @keyframes chartTitle_show1_1_keyframes {
    0%,
    100% {
      opacity: 1;
    }
    25%,
    75% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.7;
    }
  }
}
.chartTitle_show1_2 {
  animation: chartTitle_show1_2_keyframes 2s infinite;
  @keyframes chartTitle_show1_2_keyframes {
    0%,
    100% {
      fill-opacity: 1;
    }
    25%,
    75% {
      fill-opacity: 0.3;
    }
    50% {
      fill-opacity: 0.8;
    }
  }
}

// 图表标题 显示动画2
.chartTitle_show2_1 {
  animation: chartTitle_show2_1_keyframes 1s ease-out infinite;
  @keyframes chartTitle_show2_1_keyframes {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.7;
    }
  }
}
.chartTitle_show2_2 {
  animation: chartTitle_show2_2_keyframes 1s ease-out infinite;
  @keyframes chartTitle_show2_2_keyframes {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.4;
    }
  }
}
.chartTitle_show2_3 {
  animation: chartTitle_show2_3_keyframes 1s ease-out infinite;
  @keyframes chartTitle_show2_3_keyframes {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.1;
    }
  }
}
.chartTitle_show2_4 {
  animation: chartTitle_show2_4_keyframes 1s ease-out infinite;
  @keyframes chartTitle_show2_4_keyframes {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.06;
    }
  }
}

// 柱状图 显示动画1
.histogram_moving1 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: histogram_moving1_keyframes .6s linear;

  @keyframes histogram_moving1_keyframes {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
}

// 柱状图 闪烁动画1
.histogram_flicker1 {
  @keyframes histogram_flicker1_keyframes {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
}

// 条形图 闪烁动画1
.bar_flicker1 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: bar_flicker1_keyframes 2s infinite;

  @keyframes bar_flicker1_keyframes {
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  }
}

// 折线图 线条 显示动画
.line_moving1 {
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: line_moving1 2s linear;
  animation-fill-mode: forwards;

  @keyframes line_moving1 {
    to {
      stroke-dashoffset: 0;
    }
  }
}

.moving_line {
  stroke-dashoffset: 1000;
  animation: moving_line 5s linear infinite;

  @keyframes moving_line {
    from {
      stroke-dashoffset: 0;
    }
    to {
      stroke-dashoffset: 1000;
    }
  }
}

// 折线图 面积 显示动画
.lineArea_moving1 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: lineArea_moving1 .4s linear;

  @keyframes lineArea_moving1 {
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  }
}

// x轴 显示动画
.x_moving1 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: x_moving1_keyframes 1s linear;

  @keyframes x_moving1_keyframes {
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  }
}

// 进度条 闪烁动画1
.progress_flicker1 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: progress_flicker1_keyframes 2s linear infinite;

  @keyframes progress_flicker1_keyframes {
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  }
}

// 饼图 闪烁动画1
.pie_flicker1 {
  animation: pie_flicker1_keyframes 3.5s ease-in-out infinite both;
  @keyframes pie_flicker1_keyframes {
    0% {
      transform: scale(0.1);
      opacity: 0.7;
    }
    50% {
      transform: scale(0.9);
      opacity: 1;
    }
    100% {
      transform: scale(1.7);
      opacity: 0.3;
    }
  }
}
.pie_flicker2 {
  animation: pie_flicker2_keyframes 3.5s ease-in-out infinite both;
  @keyframes pie_flicker2_keyframes {
    0% {
      transform: scale(0.7);
      opacity: 0.7;
    }
    50% {
      transform: scale(1.5);
      opacity: 1;
    }
    100% {
      transform: scale(2.3);
      opacity: 0.3;
    }
  }
}

// 背景框 显示动画1
.colorBlock_show1_1 {
  animation: colorBlock_show1_1_keyframes 2s ease-out infinite;
  @keyframes colorBlock_show1_1_keyframes {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.7;
    }
  }
}
.colorBlock_show1_2 {
  animation: colorBlock_show1_2_keyframes 1s ease-out infinite;
  @keyframes colorBlock_show1_2_keyframes {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.4;
    }
  }
}
.colorBlock_show1_3 {
  animation: colorBlock_show1_3_keyframes 1s ease-out infinite;
  @keyframes colorBlock_show1_3_keyframes {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.1;
    }
  }
}
.colorBlock_show1_4 {
  animation: colorBlock_show1_4_keyframes 1s ease-out infinite;
  @keyframes colorBlock_show1_4_keyframes {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.06;
    }
  }
}

// 地图 显示动画1
.map_show1_1 {
  animation: map_show1_1_keyframes 2s ease-out infinite;
  @keyframes map_show1_1_keyframes {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.06;
    }
  }
}
.map_show1_2 {
  animation: map_show1_2_keyframes 1.5s ease-out infinite;
  @keyframes map_show1_2_keyframes {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.06;
    }
  }
}
.map_show1_3 {
  animation: map_show1_3_keyframes 2s ease-in-out infinite both;
  @keyframes map_show1_3_keyframes {
    0% {
      fill-opacity: 0.5;
    }
    50% {
      stroke-opacity: 1;
    }
    100% {
      fill-opacity: 0;
    }
  }
}
.map_show1_4 {
  animation: map_show1_4_keyframes 1.5s ease-in-out infinite both;
  @keyframes map_show1_4_keyframes {
    0% {
      stroke-opacity: 0.7;
    }
    50% {
      stroke-opacity: 1;
    }
    100% {
      stroke-opacity: 0.4;
    }
  }
}

// 弹框 显示动画1
.modal_show1 {
  animation: modal_show1_1_keyframes 0.5s ease-out;
  @keyframes modal_show1_1_keyframes {
    from {
      opacity: 0.7;
    }
    to {
      opacity: 1;
    }
  }
}
.modal_line_show1 {
  offset-path: path('M 0 0 L 500 0');
  offset-distance: 0;
  animation: modal_line_show1_keyframes 3.5s linear infinite;
  @keyframes modal_line_show1_keyframes {
    from {
      offset-distance: 0;
    }
    to {
      offset-distance: 100%;
    }
  }
}
