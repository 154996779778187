body {
  color: #fff;
  font-family: "PingFang SC", serif;

  //background: #0b1b3b;
  --ecube-main-color: #26b0ff;
  --ecube-dark-color: #27fffc;
  --ecube-dark-color2: #24dcf7;
  --ecube-warn-color: #e3a425;

  --ecube-blue-color: #4e7cff;
  --ecube-black-color: #333541;
  --ecube-gray-color: #383A47;
  --ecube-green-color: #2fd9bd;

  --datav-font-color: #BCC9D4;
  --datav-font-color-disabled: #424447;
  --datav-main-color: #2681FF;
  --datav-error-color: #FF4433;
  --datav-red-color: #F15532;
  --datav-warn-color: #F27A24;
  --datav-gray-color: #90A0AE;
  --datav-dark-color: #262c33;
  --datav-darker-color: #1a1b1d;
  --datav-main-hover-color: #409fff;
  --datav-main-click-color: #2681FF;
  --datav-pop-bg-color: #1B3964;
  --datav-slider-color: #74f0ff;
  --datav-success-color: #50E3C2;
  --datav-cp-bg-color: #13161A;
  --datav-cp-header-height: 40px;
  --datav-cp-header-font-size: 14px;
  --datav-ft-border: 1px solid #3A4659;
  --datav-cp-border: 1px solid #2C2E33;
  --datav-loading-color: #1996df;
  --datav-input-bg: #262C33;
  --datav-nav-bg: #13161A;
  --datav-nav-active-bg: #1D2126;
  --datav-nav-hover-bg: #21262b;
  --datav-left-nav-bg: #191c21;
  --datav-left-nav-hover-bg: transparent;
  --datav-left-nav-active-bg: #14161a;
  --datav-border-color: #5C6066;
  --datav-cb-bg: transparent;
  --datav-cb-bg-hover: rgba(38,129,255,0.3);
  --datav-cb-bg-disabled: rgba(58,70,89,0.3);
  --datav-cb-color: #3A4659;
  --datav-cb-color-disabled: #424447;
  --datav-cb-border: 1px solid #3A4659;
  --datav-cb-border-color-active: 1px solid #3A4659;
  --datav-cp-shadow: 0 0 1px 1px #0b0c0d;
  --datav-sw-bg: #303740;
  --datav-common-outline: 1px solid #0b0c0d;
  --datav-common-outline-hover: 1px solid #2681FF;
  --datav-common-outline-hover: 1px solid var(--datav-main-color);
  --datav-common-outline-focus: 1px solid #2681FF;
  --datav-common-outline-focus: 1px solid var(--datav-main-color);
  --datav-common-outline-disabled: 1px solid #323437;
}

// 字体
@font-face {
  font-family: "PingFang SC";
  src: url("../../assets/fonts/PingFang-Jian-ChangGuiTi-2.ttf");
}
@font-face {
  font-family: "Digifacewide";
  src: url("../../assets/fonts/digifacewide.ttf");
}
@font-face {
  font-family: "Open Sans";
  src: url("../../assets/fonts/OpenSans.ttf");
}
@font-face {
  font-family: "Furore";
  src: url("../../assets/fonts/Furore-2.otf");
}
@font-face {
  font-family: "YouSheBiaoTiHei";
  src: url("../../assets/fonts/YouSheBiaoTiHei.ttf");
}

// 控制整个滚动条
::-webkit-scrollbar {
  background-color: transparent;
  width: 4px;
  height: 4px;
  background-clip: padding-box;
}
// 滚动条两端方向按钮
::-webkit-scrollbar-button {
  /*background-color: pink;*/
}
// 滚动条中间滑动部分
::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

// 布局
.g_flexRN {
  display: flex;
  flex-flow: row;
}
.g_flex_CC {
  justify-content: center;
  align-items: center;
}
.g_flex_contentC {
  justify-content: center;
}
.g_flex_itemC {
  align-items: center;
}

.g_hide1 {
  display: -moz-box;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
  white-space: pre-wrap;
}
.flexRN {
  display: flex;
  flex-flow: row nowrap;
}
.flexRW {
  display: flex;
  flex-flow: row wrap;
}
.flexCN {
  display: flex;
  flex-flow: column nowrap;
}
.flexCW {
  display: flex;
  flex-flow: column wrap;
}
.flex_subCC {
  justify-content: center;
  align-items: center;
}
.flex_subBC {
  justify-content: space-between;
  align-items: center;
}