*, body, p {
  margin: 0;
  padding: 0;
}
.App {
  width: 100vw;
  height: 100vh;
  background: #383A47;
}

// antd: modal
.ant-modal-content {
  color: #fff;
  background: #333541;
  box-shadow: 4px 4px 4px rgba(51, 53, 65, 0.16);
  border-radius: 4px;
}
.ant-modal-header {
  padding: 8px 24px;
  background: #333541;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #23242B;
}
.ant-modal-title {
  position: relative;
  padding-left: 8px;
  padding-right: 60px;
  color: #fff;
  width: fit-content;
}
.ant-modal-title:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 10px;
  background: linear-gradient(90deg, #4E7CFF 0%, rgba(39, 62, 128, 0.102) 99.4%);
  z-index: 1;
}
.ant-modal-close-x {
  width: 38px;
  height: 38px;
  line-height: 44px;
}
.anticon {
  color: #fff;
  vertical-align: 0;
}

// antd: radio
.ant-radio-inner {
  border-color: var(--ecube-blue-color);
}
.ant-radio-disabled + span,
.ant-radio-wrapper {
  color: #fff;
}
span.ant-radio + * {
  padding-left: 4px;
  padding-right: 4px;
}

// antd: button
.ant-btn {
  padding: 6px 18px;
  font-family: "PingFang SC", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
}
.ant-btn-primary:focus,
.ant-btn-primary:active,
.ant-btn-primary:hover,
.ant-btn-primary {
  color: #fff;
  background: var(--ecube-blue-color);
  border: 1px solid var(--ecube-blue-color);
}
.ant-btn-primary.e_btn_primary_del:hover,
.ant-btn-primary.e_btn_primary_del {
  background: rgba(78, 124, 255, 0.5);
}

// antd: 组合input框
.ant-input-group.ant-input-group-compact > *:first-child, .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}