.message-container {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100vw;
  z-index: 3000;
  color: #1B3964;

  &,
  .message {
    pointer-events: none;
  }

  .message {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;

    .message_content {
      position: relative;
      display: inline-flex;
      align-items: center;
      padding: 8px 70px;
      color: #fff;
      text-shadow: 0 0 4px #fff;
      font-size: 16px;
      font-weight: 600;

      .icon {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }

      .message_bg_left {
        position: absolute;
        left: 0;
      }
      .message_bg_right {
        position: absolute;
        right: 0;
      }
    }
  }
}
