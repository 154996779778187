[data-component=ELoading] {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //background: #383a47;
  background: #000;

  .loading_x {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 500px;
    //background: rgba(255, 255, 255, .3);

    .img_loading {
      width: 300px;
      height: 300px;
    }
  }

}